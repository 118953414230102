import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Avatar, Button, ButtonBase, Chip, Grid, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import {CloseSharp} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    large_avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
}));

export const MaskField = ({source, record = {}}) => {

    let mask = record[source];

    switch (mask) {
        case 0:
            return <Chip variant="outlined" label={"Nein "} icon={<CloseSharp/>} />
        case 1:
            return <Chip variant="outlined" label={"Ja "} icon={<CheckIcon/>} color={"secondary"}/>
        case -1:
            return <Chip variant="outlined" label={"Maskenerkennung deaktiviert"} icon={<CloseSharp/>}/>
        default:
            return null
    }
}

export const Base64Image = ({source, record = {}}) => {
    return (
        <>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                    <Avatar alt={source["name"]} src={"data:image/jpeg;base64," + record[source]}/>
                </Grid>
                <Grid item>
                    <Typography component={'span'} variant="subtitle1">{record["name"]}</Typography>
                </Grid>
                <div style={{marginBottom: 15}}/>
            </Grid>
        </>
    )
};

export const Base64ShowBigImage = ({source, record = {}}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
            <Grid item>
                <ButtonBase onClick={handleClickOpen}>
                    <Avatar className={classes.large_avatar} alt={source["name"]}
                            src={"data:image/jpeg;base64," + record[source]}/>
                </ButtonBase>
            </Grid>
            <Grid item>
                <Typography component={'span'} variant="subtitle1">{record.name}</Typography>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{record["name"]}</DialogTitle>
                <DialogContent>
                    <img width={"300px"} height={"300px"} src={"data:image/jpeg;base64," + record[source]}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};

export const PassType = ({source, record = {}}) => {

    let type = record[source];
    console.log("person", record)
    if (!type) {
        return "Entry"
    } else {
        return "Exit"
    }

};
export const TempField = ({source, record = {}}) => {
    let temp = parseFloat(record[source]);
    return <Typography color={(temp > 37.3) ? "error" : "secondary"} component="div">{record[source]} °C</Typography>;
}
