import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {Badge, Box, Button, Card, CardHeader, Divider, IconButton, Paper, Tooltip} from '@material-ui/core';
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {Notifications, Settings} from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import {useRefresh} from "react-admin";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useAPI} from "../NotificationContext";
import {globals} from "../../../resources/data/globals";
import axios from 'axios';
import {markAsRead} from "../../../resources/utils";
import { ListItemAvatar,Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 360,
        maxHeight: '500px',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        border: "none",
    },
}));

export default function NotificationsList() {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const refresh = useRefresh()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { notification, count } = useAPI();
    return (
        <>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={count} color="secondary">
                    <Notifications/>
                </Badge>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Card className={classes.root}>
                    <CardHeader title="Notifications" action={
                        <IconButton aria-label="settings" href={"/#/settings"}>
                            <Settings/>
                        </IconButton>
                    } />
                    <Divider/>
                    <Paper>
                        <List style={{maxHeight: 200, overflow: 'auto'}}>
                            {(count) ? (notification.map((notif, i) => (
                                <ListItem key={i} button component={"a"} href={notif.data.link} onClick={() => markAsRead(notif.id, refresh())}>
                                    <ListItemAvatar>
                                        <Avatar alt={'checkPic'} src={notif.data.icon}/>
                                    </ListItemAvatar>
                                     <ListItemText primary={notif.data.title} secondary={notif.data.body}/>
                                </ListItem>
                            ))) : <ListItem>
                                <ListItemText primary={"Keine neuen Benachrichtigungen"}/>
                            </ListItem>}
                        </List>
                    </Paper>
                    <Divider/>
                    <Box display="flex"
                         justifyContent="flex-end"
                         p={2}>
                        <Button
                            color="primary"
                            endIcon={<ArrowRightIcon/>}
                            size="small"
                            variant="outlined"
                            href={"/#/notification"}
                        >
                            View all
                        </Button>
                    </Box>
                </Card>
            </Popover>
    </>
    );
}
