import React from "react";
import {ChipField, DateField, Show, SimpleShowLayout, TextField, RichTextField, useRefresh, UrlField} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {markAsRead} from "../../../resources/utils";
import CheckIcon from "@material-ui/icons/Check";
import {CloseSharp} from "@material-ui/icons";
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    widthFormGroup: {display: 'inline-block', margin: 10},
    heightFormGroup: {display: 'inline-grid', marginLeft: 32, width: "300px"},
}));

const AlertListShow = (props) => {
    const classes = useStyles();
    const [read, setRead] = React.useState(false);

    React.useEffect(() => {
        if(!read){
            setRead(true)
            markAsRead(props.id)
        }
    }, [])

    const CustomUrlField = ({source, record = {}}) => {
        console.log(record[source])
        return <Link href={"/#/" + record[source.link]}>Show Profile</Link>
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={"data.title"} label={"Title"}/>
                <RichTextField source={"data.body"} label={"Message"}/>
                <UrlField source={"data.link"} />
            </SimpleShowLayout>
        </Show>
    )
}

export default AlertListShow;
