import * as React from "react";
import {
    Create,
    FormTab,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
    SimpleForm
} from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {createDeviceSettings} from "../../../resources/formDefaults"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    widthFormGroup: {display: 'inline-block'},
    heightFormGroup: {display: 'inline-grid', marginLeft: 32, width: "300px"},
}));

const DeviceCreate = (props) => {
    const classes = useStyles();
    const [tempCompensation, setTempCompensation] = React.useState(false);

    return(
        <Create {...props}>
            <SimpleForm>
               {/*<TextInput validate={required()} source="mac" label={"HYCOS ID (MAC-Address)"}/>*/}
               <TextInput validate={required()} source="san_lock_alias" label={"HYCOS Name (Alias)"}/>
               <NumberInput validate={required()} source="maxVisitors" label={"Maximum Visitors"}/>
            </SimpleForm>
        </Create>
    )

    /*return (
        <Create {...props}>
            <TabbedForm initialValues={createDeviceSettings} redirect="list">
                <FormTab label={"1. Connection"}>
                    <TextInput validate={required()} source="IP_Device_PC" label={"HYCOS IP or Hostname "}/>
                    <TextInput validate={required()} source="IP_Terminal" label={"Terminal IP-Address"}/>
                </FormTab>
                <FormTab label={"2. Settings"}>
                    <TextInput formClassName={classes.heightFormGroup} validate={required()} source="companyName"
                               label={"Display Name"}/>
                    <NumberInput formClassName={classes.heightFormGroup} source="similarity"
                                 label={"Similarity 30 - 100"}
                                 min={30} max={100} validate={required()}/>
                    <NumberInput formClassName={classes.heightFormGroup} source="recoInterval"
                                 label={"Recognition interval in ms"} min={2000} max={10000} validate={required()}/>
                    <NumberInput formClassName={classes.heightFormGroup} source="recoDistance"
                                 label={"Recognition distance 0.5 ~ 3m"} min={0.5} max={3} validate={required()}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="liveIdentiLevel"
                                 choices={[
                                     {id: 0, name: 'Fast mode. No live portraits'},
                                     {id: 1, name: 'Quick mode cannot refuse to recognize portrait photos'},
                                     {id: 2, name: 'Can refuse to recognize some portrait photos'},
                                     {id: 3, name: 'Can refuse to recognize portrait photos and videos.'},

                                 ]}/>

                    <SelectInput formClassName={classes.heightFormGroup} source="passType" label={"Access type"}
                                 defaultValue={0} choices={[
                        {id: 0, name: 'Entry'},
                        {id: 1, name: 'Exit'},
                    ]}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="relayMode" choices={[
                        {id: 0, name: 'Mode 0'},
                        {id: 1, name: 'Mode 1'},
                        {id: 2, name: 'Mode 2'},
                        //{id: 100, name: 'Can refuse to recognize portrait photos and videos.'},
                    ]}/>
                    <NumberInput formClassName={classes.heightFormGroup} source="relayDelay" label={"Delay"}
                                 validate={required()}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="serialMode" choices={[
                        {id: 1, name: 'Open the door'},
                        {id: 2, name: 'No output'},
                        {id: 3, name: 'Output personnel ID'},
                        {id: 4, name: 'Output ID / IC card number'},
                        {id: 100, name: 'Personalize serial port: '},
                    ]}/>


                    <TextInput formClassName={classes.heightFormGroup} source={"serialCustomize"}
                               label={"Personalize serial port"}/>
                    <TextInput formClassName={classes.heightFormGroup} source={"strangerVoiceCustom"}
                               label={"Stranger voice customization"}
                               inputProps={{maxLength: 32}}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="strangerVoiceMode"
                                 choices={[
                                     {id: 1, name: 'Does not require voice announcement'},
                                     {id: 2, name: 'Stranger alarm'},
                                     //{id: 100, name: 'Custom'},
                                 ]}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="voiceMode" choices={[
                        {id: 1, name: 'No voice announcement'},
                        {id: 2, name: 'Broadcast name'},
                        {id: 100, name: 'Voice customization: '},
                    ]}/>
                    <TextInput formClassName={classes.heightFormGroup} source={"voiceCustom"}
                               label={"Voice customization"}
                               inputProps={{maxLength: 32}}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="wg" label={"Wiegand Output"}
                                 choices={[
                                     {id: 26, name: 'Output mode WG26'},
                                     {id: 34, name: 'Output mode WG34'},
                                     {id: 0, name: 'Output card number WG26'},
                                     {id: 1, name: 'Output personnel ID WG26'},
                                     {id: 2, name: 'Output card number WG34'},
                                     {id: 3, name: 'Output personnel ID WG34'},
                                 ]}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="tricolorLamp"
                                 label={"Red photo flood lamp"} choices={[
                        {id: 1, name: 'OFF'},
                        {id: 2, name: 'ON'},
                    ]}/>
                    <SelectInput formClassName={classes.heightFormGroup} source="displayMode" choices={[
                        {id: 1, name: 'Display name'},
                        {id: 100, name: 'Display customization: '},
                    ]}/>
                    <TextInput formClassName={classes.heightFormGroup} source="diplayCustom"
                               label={"Display customization"}
                    />
                    {/!*<SelectInput formClassName={classes.heightFormGroup} source="icNO" label={"IC card"} defaultValue={1}*!/}
                    {/!*             choices={[*!/}
                    {/!*                 {id: 1, name: 'Swipe card'},*!/}
                    {/!*                 {id: 2, name: 'Human & card comparison'},*!/}
                    {/!*             ]}/>*!/}
                    {/!*<SelectInput formClassName={classes.heightFormGroup} source="idCardNo" label={"IC card"}*!/}
                    {/!*             defaultValue={1} choices={[*!/}
                    {/!*    {id: 1, name: 'OFF'},*!/}
                    {/!*    {id: 2, name: 'Person & ID comparison'},*!/}
                    {/!*    {id: 2, name: 'Person ID comparison (visitor version)'}*!/}
                    {/!*]}/>*!/}

                </FormTab>

                <FormTab label={"3. Temperature"}>
                    <TextInput source={"standardBodyTemp"} label={"Fever threshold in °C"}
                               validate={required()}/>
                    <RadioButtonGroupInput source="tempCompensation" choices={[
                        {id: 0, name: 'Automatic'},
                        {id: 1, name: 'Manual'},
                    ]}
                                           onChange={(e) => setTempCompensation(e)}
                    />
                    <div style={{display: (tempCompensation) ? 'block' : 'none'}}>
                        <NumberInput source={"tempCompensation"} label={"Temperature compensation"} min={-1} max={1}
                                     InputProps={{
                                         endAdornment: <Tooltip
                                             title="Temperature compensation, range (-1 ~ 1), positive number means upward compensation, negative number downward compensation"><InfoIcon/></Tooltip>
                                     }}/>
                    </div>

                    <RadioButtonGroupInput source="isWearingMask" choices={[
                        {id: 0, name: 'No'},
                        {id: 1, name: 'Yes'},
                    ]} label={"Require facemask to access"}
                                           formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isStrangerRecord" choices={[
                        {id: 0, name: 'No'},
                        {id: 1, name: 'Yes'},
                    ]} label={"Save stranger records"} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isFan" choices={[
                        {id: 0, name: 'No'},
                        {id: 1, name: 'Yes'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isLowFeverAdopt" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isLowTempAdopt" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isStandardTempAdopt" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isBodyTempAlarm" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isBodyTempStart" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>
                    <RadioButtonGroupInput source="isHighFeverAdopt" choices={[
                        {id: 0, name: 'OFF'},
                        {id: 1, name: 'ON'},
                    ]} formClassName={classes.heightFormGroup}/>

                </FormTab>
            </TabbedForm>
        </Create>
    )*/
};

export default DeviceCreate
