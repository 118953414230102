import React from 'react';
import {useRefresh} from 'react-admin';
import {Container, Grid, makeStyles} from '@material-ui/core';
import TodayVisitor from './TodayVisitor';
import LatestVisitors from './LatestVisitors';
import CurrentVisitors from './CurrentVisitors';
import TotalVisitors from './TotalVisitors';
import Alarms from './Alarms';
import TrafficByDevice from './TrafficByDevice';
import {DashboardContextProvider} from "./DashboardContext";
import {useRecursiveTimeout} from "../../../resources/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        //minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const Dashboard = (props) => {
    const classes = useStyles();
    const refresh = useRefresh()
    useRecursiveTimeout(() => refresh(), 10000)

    return (
        <DashboardContextProvider>
            <div className={classes.root}>
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <CurrentVisitors/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <TodayVisitor/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <TotalVisitors/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <Alarms/>
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={12}
                            xl={9}
                            xs={12}
                        >
                            <LatestVisitors/>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <TrafficByDevice/>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xl={3}
                            xs={12}
                        >

                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={12}
                            xl={9}
                            xs={12}
                        >

                        </Grid>
                    </Grid>
                </Container>
            </div>
        </DashboardContextProvider>
    );
};

export default Dashboard;
