import React from "react";
import {ChipField, DateField, Show, SimpleShowLayout, TextField, TopToolbar,DeleteButton} from 'react-admin';
import {Base64ShowBigImage, MaskField, TempField} from "../CustomFields";
import {makeStyles} from "@material-ui/core/styles";
import { translate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    widthFormGroup: {display: 'inline-block', margin: 10},
    heightFormGroup: {display: 'inline-grid', marginLeft: 32},
}));

const PeopleShowActions = ({ permissions, basePath, data, resource }) => (
    <TopToolbar>
        <DeleteButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
);

const PeopleListShow = (props) => {
    const classes = useStyles();

    return (
        <Show actions={<PeopleShowActions/>} {...props}>
            <SimpleShowLayout>
                <Base64ShowBigImage source={"picture"}/>
                <DateField className={classes.widthFormGroup} source="checkTime" showTime={true} label={"Date"}/>
                <TextField className={classes.widthFormGroup} source="timezone"/>


                <TempField source={"temp"} addLabel label={"Temperatur"}/>
                <MaskField source={"mask"} addLabel label={"Trägt Maske"}/>
                <MaskField source={"disinfected"} addLabel label={"Desinfiziert"} />
                <TextField source="san_lock_alias" label={"HYCOS"}/>
                <TextField source="san_lock_mac" label={"HYCOS-ID"}/>
            </SimpleShowLayout>
        </Show>
    )
}

export default PeopleListShow;
