import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FilterLiveSearch,
    List,
    NullableBooleanInput,
    SearchInput,
    SimpleList,
    TextField,
    ArrayField,
    useRefresh,
    RichTextField,
    CheckboxGroupInput
} from 'react-admin';
import {Avatar, Card, CardContent, Chip, useMediaQuery} from '@material-ui/core';
import {Base64Image, MaskField, TempField} from "../CustomFields";
import {connect} from 'react-redux'; /* code change */
import {useRecursiveTimeout} from "../../../resources/utils";
import {AlertType, HasBeenRead, IsHighTemp, LastVisitedFilter} from './AlertFilter';
import {CloseSharp} from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import theme from "../../../theme";
import AlertListShow from "./AlertListShow";

const FilterSmall = (props) => (
    <Filter {...props}>
        <DateInput source="last_seen_gte" label={"Date"}/>
        <NullableBooleanInput source="read" />

    </Filter>
);

const FilterSidebar = () => (
    <div style={{ marginLeft: '1em' }}>
        <Card>
            <CardContent>
                <LastVisitedFilter />
                <AlertType/>
                <HasBeenRead/>
            </CardContent>
        </Card>
    </div>
);

const AlertTypeField = ({source, record = {}}) => {

    let mask = record[source];
    mask = mask.replace("App\\Notifications\\", "");

    switch (mask) {
        case "MaskAlarm":
            return "Mask Alarm"
        case "AccessAlarm":
            return "Access Alarm"
        case  "TemperatureAlarm":
            return "High Temperature"
        default:
            return null
    }
}

const AlertList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const refresh = useRefresh()
    useRecursiveTimeout(() => refresh(), 10000)

    return (
        <List  {...props}
               key={"list"}
               aside={(!isSmall) ? <FilterSidebar /> : null}
               filters={(isSmall) ? <FilterSmall/> : null}
               exporter={false}
               sort={{ field: 'created_at', order: 'DESC' }}
        >
            {isSmall ? (
                <SimpleList
                    linkType={"show"}
                    primaryText={record => record.data.title}
                    secondaryText={record => record.data.body}
                    tertiaryText={record => (record.read_at === null) ? <span style={{
                        height: "15px",
                        width: "15px",
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: "50%",
                        display: "inline-block"
                    }}/> : null}
                />
            ) : (
                <Datagrid
                    rowClick="expand" expand={<AlertListShow {...props}/>}>
                    <AlertTypeField source={"type"}/>
                    <DateField source="created_at" showTime={true}/>
                    <DateField source="read_at" showTime={true}/>
                </Datagrid>
            )
            }
        </List>
    )
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AlertList);
