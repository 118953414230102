import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Avatar, Box, Card, CardContent, colors, Grid, makeStyles, Typography} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import {useAPI} from "./DashboardContext";
import {Error} from "react-admin"
import { translate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56
    },
    differenceIconPositive: {
        color: colors.green[900]
    },
    differenceValuePositive: {
        color: colors.green[900],
        marginRight: theme.spacing(1)
    },
    differenceIconNegative: {
        color: colors.red[900]
    },
    differenceValueNegative: {
        color: colors.red[900],
        marginRight: theme.spacing(1)
    }
}));

const TotalVisitors = ({translate, className, ...rest}) => {
    const classes = useStyles();
    const { visitorTotalCount } = useAPI()
    const [isPositive, setPositive] = React.useState(false)

    React.useEffect(() => {
        setPositive(visitorTotalCount.growthRate > 0)
    }, [])

    if(visitorTotalCount) {
        return (
            <Card
                className={clsx(classes.root, className)}
                {...rest}
            >
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                            >
                                {translate("dashboard.totalVisitors.title")}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h3"
                            >
                                {visitorTotalCount.currentMonth}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <PeopleIcon/>
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                    >
                        {(isPositive) ? (<ArrowUpwardIcon className={classes.differenceIconPositive}/>) : (
                            <ArrowDownwardIcon className={classes.differenceIconNegative}/>)}
                        <Typography
                            className={(isPositive) ? classes.differenceValuePositive : classes.differenceValueNegative}
                            variant="body2"
                        >
                            {visitorTotalCount.growthRate} %
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            {translate("dashboard.totalVisitors.growth")} ({visitorTotalCount.lastMonth})
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    }else{
        return <Error/>
    }
};

TotalVisitors.propTypes = {
    className: PropTypes.string
};

export default translate(TotalVisitors);
