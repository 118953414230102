import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Doughnut, Pie} from 'react-chartjs-2';
import {Box, Card, CardContent, CardHeader, colors, Divider, makeStyles, Typography, useTheme} from '@material-ui/core';
import {Check, Close} from "@material-ui/icons";
import {useAPI} from "./DashboardContext";
import { translate } from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const TrafficByDevice = ({translate, className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {visitorTotalCount, alarms} = useAPI();

  const data = {
    datasets: [
      {
        data: [
          visitorTotalCount.total - (visitorTotalCount.noMask + visitorTotalCount.fever),
          visitorTotalCount.noMask,
          visitorTotalCount.fever
        ],
        backgroundColor: [
          colors.green[600],
          colors.orange[600],
          colors.red[600],

        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['OK', 'No Mask','Alarm']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: translate("dashboard.statistic.ok"),
      value: visitorTotalCount.total - (visitorTotalCount.noMask + visitorTotalCount.fever),
      icon: Check,
      color: colors.green[600]
    },
    {
      title: translate("dashboard.statistic.noMask"),
      value: visitorTotalCount.noMask,
      icon: Close,
      color: colors.orange[600]
    },
    {
      title: translate("dashboard.statistic.alarm"),
      value: visitorTotalCount.fever,
      icon: Close,
      color: colors.red[600]
    }
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title={translate("dashboard.statistic.title")} />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string
};

export default translate(TrafficByDevice);
