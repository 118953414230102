import React from 'react';
import {Title} from "react-admin"
import {Box, Container, makeStyles} from '@material-ui/core';
import Notifications from './Notifications';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();

  return (
      <Container maxWidth="lg" className={classes.root}>
          <Title>{"Settings"}</Title>
        <Notifications />
        <Box mt={3}>
        </Box>
      </Container>
  );
};

export default SettingsView;
