import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {Send, Settings, Error, Email} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {useQueryWithStore, useMutation} from "react-admin";
import CircularProgress from '@material-ui/core/CircularProgress';
import {func} from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export default function ChipsArray() {
    const classes = useStyles();

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'dashboard',
        payload: { id: 'get_mail' }
    });

    const [chipData, setChipData] = React.useState([{key: 0, label: "test"}]);
    const [mail, setMail] = React.useState("");
    const [newMail, setNew] = React.useState();

    if (!loaded) return <CircularProgress />;
    if (error) return <Error />;

    const handleDelete = (chipToDelete) => () => {
        console.log(chipToDelete)

    };

    const Send_Button = () => {
        const [add, { loading }] = useMutation({
            type: 'create',
            resource: 'add_mail',
            payload: { data: {email: mail} }
        });
        return <IconButton aria-label="send"
                    onClick={add}
                    disabled={loading}
        >
                <Send/>
                </IconButton>
    }


    return (
        <>
            <TextField id="standard-basic" label="Email hinzufügen" variant={"filled"}
                       onChange={(e) => setMail(e.target.value)}
                       InputProps={{endAdornment: <Send_Button />}}
            />

            <Paper component="ul" className={classes.root}>
            {data.mails.map((mails) => {

                return (
                    <li key={mails.id}>
                        <Chip
                            label={mails.email}
                            onDelete={handleDelete(mails)}
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </Paper>
        </>
    );
}
