// in src/App.js
import * as React from "react";
import {Admin, defaultI18nProvider, ListGuesser, Resource, ShowGuesser} from 'react-admin';

import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import {ThemeProvider} from '@material-ui/core/styles';
import {Alarm, People} from "@material-ui/icons";

import DeviceList from "./components/Lists/DeviceList/DeviceList"
import DeviceCreate from "./components/Lists/DeviceList/DeciveCreate";
import DeviceEdit from "./components/Lists/DeviceList/DeviceEdit";
import DeviceShow from "./components/Lists/DeviceList/DeviceListShow";


import PeopleList from "./components/Lists/PeopleList/PeopleList";
import PeopleListShow from "./components/Lists/PeopleList/PeopleListShow";

import dataProvider from './resources/data/dataProvider';
import authProvider from "./resources/data/authProvider";
import Login from "./components/Pages/Login";
import MyLayout from "./components/Layout/MyLayout";
import MyCustomRoutes from "./components/MyCustomRoutes";
import Dashboard from "./components/Pages/Dashboard";
import theme from "./theme";
import AlertList from "./components/Lists/AlertList/AlertList";
import AlertListShow from "./components/Lists/AlertList/AlertListShow";

import germanCustomMessages from './resources/i18n/german';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { translate } from 'react-admin';
import germanMessages from 'ra-language-german';

const i18nProvider = polyglotI18nProvider(() => germanCustomMessages, 'de');

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <Admin layout={MyLayout}
                   customRoutes={MyCustomRoutes}
                   theme={theme}
                   dataProvider={dataProvider}
                   authProvider={authProvider}
                   loginPage={Login}
                   dashboard={Dashboard}
                   i18nProvider={i18nProvider}
                 >

                <Resource name="people" icon={People}
                          show={PeopleListShow}
                          list={PeopleList}/>

                <Resource name="device" icon={StayCurrentPortraitIcon} list={DeviceList}
                          create={DeviceCreate}
                          edit={DeviceEdit}
                          show={DeviceShow}/>

                <Resource name="notification" options={{label: 'Alerts'}} icon={Alarm}
                          list={AlertList}
                          show={AlertListShow}/>

                {/*<Resource name="person" options={{label: 'Registered People', myCustomAttr: "10"}} icon={PersonAdd}
                          create={PersonCreate}
                          list={PersonList}/>*/}


            </Admin>
        </ThemeProvider>
    )

};

export default App;
