import * as React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import {Error, FilterList, FilterListItem, Loading, useQueryWithStore,SelectInput} from 'react-admin';
import {endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subMonths, subWeeks} from 'date-fns';
import {AcUnit, Face, Smartphone} from "@material-ui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {CircularProgress} from "@material-ui/core"
import Select from "@material-ui/core/Select/Select";
import {MenuItem} from "@material-ui/core";
export const LastVisitedFilter = () => (
    <FilterList label="resources.people.filters.time_visited.name" icon={<AccessTimeIcon />}>
        <FilterListItem
            label="resources.people.filters.time_visited.today"
            value={{
                //time:{
                    last_seen_gte: format(new Date(), "YYYY-MM-DD"),
                    last_seen_lte: undefined,
                //}
            }}
        />
        <FilterListItem
            label="resources.people.filters.time_visited.this_week"
            value={{
               // time:{
                last_seen_gte: startOfWeek(new Date()).toISOString(),
                last_seen_lte: endOfWeek(new Date()).toISOString()}
            //}
        }
        />
        <FilterListItem
            label="resources.people.filters.time_visited.last_week"
            value={{
                //time:{
                last_seen_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
                last_seen_lte: startOfWeek(new Date()).toISOString(),}
            //}
        }
        />
        <FilterListItem
            label="resources.people.filters.time_visited.this_month"
            value={{
                //time:{
                last_seen_gte: startOfMonth(new Date()).toISOString(),
                last_seen_lte: endOfMonth(new Date()).toISOString()
                //}
            }}
        />
        <FilterListItem
            label="resources.people.filters.time_visited.last_month"
            value={{
                //time:{
                last_seen_gte: subMonths(startOfMonth(new Date()),1).toISOString(),
                last_seen_lte: subMonths(endOfMonth(new Date()),1).toISOString(),
                //}
            }}
        />
    </FilterList>
);
export const HasMaskFilter = () => (
    <FilterList
        label="resources.people.filters.mask.name"
        icon={<Face />}
    >
        <FilterListItem
            label="resources.people.filters.mask.yes"
            value={{
                mask: 1,
            }}
        />
        <FilterListItem
            label="resources.people.filters.mask.no"
            value={{
                mask: 0,
            }}
        />
    </FilterList>
);

export const IsHighTemp = () => (
    <FilterList
        label="resources.people.filters.temp.name"
        icon={<AcUnit/>}
    >
        <FilterListItem
            label="resources.people.filters.temp.yes"
            value={{
                temp: ">=",
            }}
        />
        <FilterListItem
            label="resources.people.filters.temp.no"
            value={{
                temp: "<=",
            }}
        />
    </FilterList>
);

export const HasDeviceFilter = (props) => {

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'devices',
        payload: { id: 'filters' }
    });

     if (!loaded) return <CircularProgress/>;
    if (error) return "Error";

    if(!props.mobile) {
        return (
            <FilterList
                label="resources.people.filters.device"
                icon={<Smartphone/>}
            >
                {
                    data.devices.map((device, i) => (
                        <FilterListItem
                            key={i}
                            label={device.san_lock_alias + " (" + device.mac + ")"}
                            value={{
                                device: device.id,
                            }}
                        />
                    ))
                }
            </FilterList>
        )
    }else{

       const arr = data.devices.map((device, i) => ({
                    id: device.id, name: device.san_lock_alias + " (" + device.mac + ")"
                }))

        return (
        <SelectInput label={"resources.people.filters.device"} choices={[arr]} />
        )
    }
};
