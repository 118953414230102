import React from 'react';
import {useQueryWithStore} from "react-admin";
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';

const APIContext = React.createContext(null);
export const NotificationContextProvider = ({children}) => {

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'notification',
        payload: { id: 'allNotifications' }
    });

    if (!loaded) return <CircularProgress color="secondary" style={{width: "1%", height: "1%"}}/>;
    if (error) return <ErrorIcon/>;

    return(
        <APIContext.Provider value={data}>
            {children}
        </APIContext.Provider>
    )
}

export function useAPI() {
    const context = React.useContext(APIContext);
    if (context === undefined) {
        return Promise.reject();
    }
    return context;
}
