import React from 'react'
import {AppBar} from 'react-admin'
import {withStyles} from '@material-ui/core/styles'
import MyUserMenu from "./AppBar/MyUserMenu";
import Logo from "../../resources/img/hycos-logo.png"
import NotificationsMenuList from "./AppBar/NotificationsMenuList"
import {ButtonBase} from "@material-ui/core";
import {NotificationContextProvider} from "./NotificationContext";

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    img:{
      padding: 5
    },
    spacer: {
        flex: 1,
    }
}

const MyAppBar = withStyles(styles)(({ classes, ...props }) => {



    return (
        <AppBar {...props} /*userMenu={<MyUserMenu/>}*/>
           <ButtonBase href={"/#/"}><img height={20} src={Logo} alt={"logo"} className={classes.img}/></ButtonBase>
            {/*<Typography
                variant="h5"
                color="inherit"
                id="react-admin-title"
            />*/}
            <span className={classes.spacer}/>
            <NotificationContextProvider>
                <NotificationsMenuList/>
            </NotificationContextProvider>

        </AppBar>
    )
})

export default MyAppBar
