const germanMiscTranslations = {
        appBar: {
            notifications: {},
        },
        buttons:{
            view_all: "Alle anzeigen"
        },
        dashboard: {
            totalVisitors: {
                title: "Anzahl der Besucheraufzeichnungen",
                growth: "Seit letzten Monat"
            },
            currentVisitors: {
                title: "Aktuelle Besucheranzahl"
            },
            enterExit: {
                title: "Besucher sind:",
                enter: "Gekommen",
                exit:  "Gegangen"
            },
            alarms: {
                title: "Benachrichtigungen",
                unread: "Neu",
                total: "Insgesammt"

            },
            latestVisitors: {
                title: "Die letzten Besucher"
            },
            statistic: {
                title: "Monatliche Statistik",
                ok: "Normal",
                alarm: "Alarm",
                noMask: "Keine Maske"
            }
        }
}
export default germanMiscTranslations
