const germanResources = {
    resources: {
        people: {
            name: "Besucher",
            fields: {
                checkTime: "Datum",
                picture: "Besucher",
                temp: "Temperatur",
                mask: "Trägt Maske",
                disinfected: "Hände desiniziert",
            },
            filters: {
                time_visited:{
                    name: 'Zeit',
                    today: 'Heute',
                    this_week: 'Diese Woche',
                    last_week: 'Letzte Woche',
                    this_month: 'Dieser Monat',
                    last_month: 'Letzer Monat',
                },
                mask:{
                  name: 'Trug Maske',
                  yes: 'Ja',
                  no: 'Nein'
                },
                temp:{
                    name:  'Über 37.3 °C',
                    yes: 'Ja',
                    no: 'Nein'
                },
                device: 'Filtern nach Schleuse',
            },
        },
        device: {
            name: "Meine HYCOS Geräte",
            fields: {
                active: "Aktiv",
                mask: "Trägt Maske",
                desinfected_stand: "Hat noch Desinfektionsmittel"
            },
        },
        notification: {
            name: "Benachrichtigungen",
            fields: {
                type: "Typ",
                created_at: "Erhalten",
                read_at: "Gelesen"
            },
            filters: {
                time_visited:{
                    name: 'Erhalten',
                    today: 'Heute',
                    this_week: 'Diese Woche',
                    last_week: 'Letzte Woche',
                    this_month: 'Dieser Monat',
                    last_month: 'Letzer Monat',
                },
                alert_type:{
                    name: "Benachrichtigungsgrund",
                    high: "Zuhohe Temperatur",
                    mask: "Keine Maske",
                    access: "Keine Maske & hohe Temperatur"
                },
                read:{
                    name: "Gelesen",
                    yes: "Ja",
                    no: "Nein"
                }
            }
        }
    }
}
export default germanResources
