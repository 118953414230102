import {Layout} from 'react-admin';
import MyAppBar from './MyAppBar';
import React from "react";

const MyLayout = (props) => <Layout
    {...props}
    appBar={MyAppBar}
    //menu={MyMenu}
/>;

export default MyLayout;
