import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Avatar, Box, Card, CardContent, colors, Grid, LinearProgress, makeStyles, Typography} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import {useAPI} from "./DashboardContext";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Button, translate, useMutation} from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }
}));

const CurrentVisitors = ({translate, className, ...rest }) => {
  const classes = useStyles();
  const { currentVisitorCount } = useAPI()

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const ResetButton = ({ record }) => {
    const [reset, { loading }] = useMutation({
      type: 'create',
      resource: 'dashboard/resetTotal',
      payload: { id: 1, data: { isApproved: true } }
    });
    return <Button label="Zurücksetzen" onClick={reset} disabled={loading} />;
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {translate("dashboard.currentVisitors.title")}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {currentVisitorCount.total.current} - {currentVisitorCount.total.max} <ResetButton/>
            </Typography>
          </Grid>
          {/*<Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar>
          </Grid>*/}
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={((currentVisitorCount.total.current === 0) ? 1 : currentVisitorCount.total.current / currentVisitorCount.total.max) * 100}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CurrentVisitors.propTypes = {
  className: PropTypes.string
};

export default translate(CurrentVisitors);
