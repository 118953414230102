import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Avatar, Box, Card, CardContent, colors, Grid, makeStyles, Typography} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import {useAPI} from "./DashboardContext";
import {Error} from "react-admin"
import {translate} from 'react-admin';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    avatar: {
        backgroundColor: colors.blue[600],
        height: 56,
        width: 56
    },
    differenceIconPositive: {
        color: colors.green[900]
    },
    differenceValuePositive: {
        color: colors.green[900],
        marginRight: theme.spacing(1)
    }
}));

const TodayVisitor = ({translate, className, ...rest}) => {
    const classes = useStyles();
    const {currentVisitorCount} = useAPI()

    if (currentVisitorCount) {
        return (
            <Card
                className={clsx(classes.root, className)}
                {...rest}
            >
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                            >
                                {translate("dashboard.enterExit.title")}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {translate("dashboard.enterExit.enter")} : {currentVisitorCount.total.entry}

                            </Typography> <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {translate("dashboard.enterExit.exit")} : {currentVisitorCount.total.exit}
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <PeopleIcon/>
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    } else {
        return <Error/>
    }
};

TodayVisitor.propTypes = {
    className: PropTypes.string
};

export default translate(TodayVisitor);
