import * as React from "react";
import {MenuItemLink, UserMenu} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/settings"
        primaryText="System Settings"
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
));

const AccountMenu = React.forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/account"
        primaryText="Account"
        leftIcon={<AccountBoxIcon />}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
        <AccountMenu/>
    </UserMenu>
);

export default MyUserMenu
