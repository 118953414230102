import React from "react"
import {globals} from "./data/globals";
import axios from "axios";
import {useRefresh} from "react-admin";

export const isDev = (process.env.NODE_ENV === 'development');

export const useRecursiveTimeout = (callback, delay) => {
    /*
    * The way this works is that the tick function will invoke the callback provided (which is the function to recursively call) and
    * then schedule it with setTimeout. Once the callback completes the return value is checked to see if it is a Promise, and if it is,
    * await for the Promise to complete before scheduling the next iteration, otherwise it’ll schedule it.
    * This means that it can be used in both a synchronous and asynchronous manner.
    */
    const savedCallback = React.useRef(callback)

    React.useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    React.useEffect(() => {
        let id

        function tick() {
            const ret = savedCallback.current()

            if (ret instanceof Promise) {
                ret.then(() => {
                    if (delay !== null) {
                        id = setTimeout(tick, delay)
                    }
                })
            } else {
                if (delay !== null) {
                    id = setTimeout(tick, delay)
                }
            }
        }

        if (delay !== null) {
            id = setTimeout(tick, delay)
            return () => id && clearTimeout(id)
        }
    }, [delay])
}

export const markAsRead = (id, hook) => {
    let config = {
        method: 'get',
        url: globals.API_URL + '/notification/markAsRead/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    axios(config)
        .then(function (response) {
            hook();
        })
        .catch(function (error) {
            console.log(error);
        });
}
