import * as React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import {FilterList, FilterListItem} from 'react-admin';
import {endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subMonths, subWeeks} from 'date-fns';
import {AcUnit, Face, Notifications} from "@material-ui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export const LastVisitedFilter = () => (
    <FilterList label="resources.notification.filters.time_visited.name" icon={<AccessTimeIcon />}>
        <FilterListItem
            label="resources.notification.filters.time_visited.today"
            value={{
                //time:{
                last_seen_gte: format(new Date(), "YYYY-MM-DD"),
                last_seen_lte: undefined,
                //}
            }}
        />
        <FilterListItem
            label="resources.notification.filters.time_visited.this_week"
            value={{
                // time:{
                last_seen_gte: startOfWeek(new Date()).toISOString(),
                last_seen_lte: endOfWeek(new Date()).toISOString()}
                //}
            }
        />
        <FilterListItem
            label="resources.notification.filters.time_visited.last_week"
            value={{
                //time:{
                last_seen_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
                last_seen_lte: startOfWeek(new Date()).toISOString(),}
                //}
            }
        />
        <FilterListItem
            label="resources.notification.filters.time_visited.this_month"
            value={{
                //time:{
                last_seen_gte: startOfMonth(new Date()).toISOString(),
                last_seen_lte: endOfMonth(new Date()).toISOString()
                //}
            }}
        />
        <FilterListItem
            label="resources.notification.filters.time_visited.last_month"
            value={{
                //time:{
                last_seen_gte: subMonths(startOfMonth(new Date()),1).toISOString(),
                last_seen_lte: subMonths(endOfMonth(new Date()),1).toISOString(),
                //}
            }}
        />
    </FilterList>
);

export const AlertType = () => (
    <FilterList
        label="resources.notification.filters.alert_type.name"
        icon={<Notifications/>}
    >
        <FilterListItem
            label="resources.notification.filters.alert_type.high"
            value={{
                alarm: "App\\Notifications\\TemperatureAlarm",
            }}
        />
        <FilterListItem
            label="resources.notification.filters.alert_type.mask"
            value={{
                alarm: "App\\Notifications\\MaskAlarm",
            }}
        />
        <FilterListItem
            label="resources.notification.filters.alert_type.access"
            value={{
                alarm: "App\\Notifications\\AccessAlarm",
            }}
        />
    </FilterList>
);
export const HasBeenRead = () => (
    <FilterList
        label="resources.notification.filters.read.name"
        icon={<MailIcon />}
    >
        <FilterListItem
            label="resources.notification.filters.read.yes"
            value={{ read: true }}
        />
        <FilterListItem
            label="resources.notification.filters.read.no"
            value={{ read: false }}
        />
    </FilterList>
);
