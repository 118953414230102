import germanMessages from './germanMessages';
import germanResources from "./germanResources";
import germanMiscTranslations from "./germanMiscTranslations";

const customGermanMessages = {
    ...germanMessages,
    ...germanResources,
    ...germanMiscTranslations
}

export default customGermanMessages;
