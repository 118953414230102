import React from "react";
import {Datagrid, NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {CloseSharp} from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    widthFormGroup: {display: 'inline-block', margin: 10},
    heightFormGroup: {display: 'inline-grid', marginLeft: 32, width: "300px"},
}));

const DeviceShow = (props) => {
    const classes = useStyles();

    const BooleanField = ({source, record = {}}) => {
        return (record[source]) ? <CheckIcon/> : <CloseSharp/>
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={"mac"} label={"HYCOS IP"}/>
                <TextField source={"san_lock_alias"} label={"IP Terminal"}/>
                <BooleanField addLabel source={"mask"} label={"Mask required for entry"}/>
                <BooleanField addLabel source={"active"} />
                <BooleanField source={"desinfected_stand"} />
                {/*<BooleanField addLabel source={"tempCompensation"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isFan"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isLowFeverAdopt"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isLowTempAdopt"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isStandardTempAdopt"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isBodyTempAlarm"} className={classes.heightFormGroup}/>*/}
                {/*<BooleanField addLabel source={"isHighFeverAdopt"} className={classes.heightFormGroup}/>*/}
            </SimpleShowLayout>
        </Show>
    )
}

export default DeviceShow;
