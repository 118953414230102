import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FilterLiveSearch,
    List,
    NullableBooleanInput,
    SearchInput,
    SimpleList,
    TextField,
    useRefresh
} from 'react-admin';
import {Avatar, Card, CardContent, useMediaQuery} from '@material-ui/core';
import {Base64Image, MaskField, TempField} from "../CustomFields";
import {connect} from 'react-redux'; /* code change */
import {useRecursiveTimeout} from "../../../resources/utils";
import {HasMaskFilter, IsHighTemp, LastVisitedFilter, HasDeviceFilter} from './PeopleFilter';
import { translate } from 'react-admin';
const FilterSmall = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SearchInput source="" />
        <DateInput source="last_seen_gte" label={"Date"}/>
        <NullableBooleanInput source="mask" />
    </Filter>
);

const FilterSidebar = () => (
    <div style={{ marginLeft: '1em' }}>
        <Card>
            <CardContent>
                <FilterLiveSearch/>
                <LastVisitedFilter />
                <HasMaskFilter source={"mask"}/>
                <IsHighTemp/>
                <HasDeviceFilter/>
            </CardContent>
        </Card>
    </div>
);

const PeopleList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const refresh = useRefresh()
    useRecursiveTimeout(() => refresh(), 10000)

    return (
        <List  {...props}
               key={"list"}
               aside={(!isSmall) ? <FilterSidebar /> : null}
               filters={(isSmall) ? <FilterSmall/> : null}
               exporter={false}
               sort={{ field: 'checkTime', order: 'DESC' }}>
            {isSmall ? (
                <SimpleList
                    linkType={"show"}
                    primaryText={record => record.name}
                    secondaryText={record => <><TempField record={record} source={"temp"}/>
                    <div>{(record.mask == 0) ? " Maske: ❌ " : "Maske: ✔️"} {(record.disinfected == 0) ? " Desinfiziert: ❌ " : "Desinfiziert: ✔️"}</div>
                    <div>{record.san_lock_alias}</div>
                    </>}
                    tertiaryText={record => record.checkTime}
                    leftIcon={record => {
                        return (<Avatar src={"data:image/jpeg;base64," + record.picture}/>)
                    }}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="checkTime" showTime={true} /*label={"Date"}*//>
                    <Base64Image source={"picture"}/>
                    <TempField source="temp"/>
                    <MaskField source={"mask"}/>
                    <MaskField source={"disinfected"}/>
                    <TextField source="san_lock_alias" label={"HYCOS Name"}/>
                    <TextField source="san_lock_mac" label={"HYCOS ID"}/>
                </Datagrid>
            )
            }
        </List>
    )
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(PeopleList);
