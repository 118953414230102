import React from 'react';
import {Datagrid, EditButton, List, ShowButton, SimpleList, TextField, NumberField} from 'react-admin';
import {connect} from 'react-redux'; /* code change */
import {useMediaQuery} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import {CloseSharp, MeetingRoom} from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CheckIcon from "@material-ui/icons/Check";
import { useMutation, Button } from 'react-admin';
const OpenDoorButton = ({source, record = {}}) => {

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const openDoor = () => {
        let data = JSON.stringify({
            "target": record["IP_Terminal"],
            "type": "device/openDoorControl",
            "data": {
                "pass": "123456"
            }
        })
        let config = {
            method: 'post',
            url: `https://${record["IP_Device_PC"]}:80/terminal`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config).then((response => {
            if(response.data.success){
                setOpen(true);
            }
        })).catch((e) => console.log(e))
    }

    return(
        <>
        <Tooltip title={"Open the HYCOS manually"}>
            <Button variant="text" color="primary" startIcon={<MeetingRoom/>} onClick={() => openDoor()}>
                Open
            </Button >
        </Tooltip>

            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <MuiAlert severity="success">
                    {record["MAC_Device_ID"]} opened successfully
                </MuiAlert>
            </Snackbar>

      </>
    )
}

const DeviceList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const BooleanField = ({source, record = {}}) => {
        return (record[source]) ? <CheckIcon/> : <CloseSharp/>
    }

    const BesucherField = ({source, record = {}}) => {
        return record["eingang"] - record["ausgang"]
    }

    const ResetButton = ({ record }) => {
        const [reset, { loading }] = useMutation({
            type: 'create',
            resource: 'dashboard/reset/' + record.id,
            payload: { id: record.id, data: { isApproved: true } }
        });
        return <Button label="Besucherzähler auf 0" onClick={reset} disabled={loading} />;
    };

    return (
        <List  {...props} key={"list"} exporter={false} bulkActionButtons={false}>
            {isSmall ? (
                <SimpleList
                    linkType={record => "show"}
                    key={"mobile"}
                    primaryText={record => ("HYCOS: " + record.san_lock_alias)}
                    secondaryText={record => (record.mac)}
                    rightIcon={record => <SettingsIcon/>}
                />
            ) : (
                <Datagrid key={"desktop"}>
                    <TextField source="san_lock_alias" label={"HYCOS Name"}/>
                    <TextField source="mac" label={"HYCOS ID"}/>
                    <BooleanField source={"active"}/>
                    <BooleanField source={"mask"}/>
                    <BooleanField source={"desinfected_stand"}/>
                    <BesucherField/>

                    <EditButton/>
                    <ShowButton/>

                    <ResetButton/>

                </Datagrid>
            )

            }
        </List>
    )
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(DeviceList);
