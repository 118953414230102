// in src/authProvider.js
import {globals} from "./globals";
import axios from "axios";
import {subscribeUser} from "../workers/subscription";

export default {
    // called when the user attempts to log in
    login: ({ email, password }) => {
        let data= JSON.stringify({"email":email,"password":password});
        const request = {
            url: globals.API_URL+'/login',
            method: 'POST',
            data: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        return axios(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            localStorage.setItem('token', response.data.access_token);
            subscribeUser();
        }).catch((e) => {
            console.log(e)
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        if(localStorage.getItem('token')){
           const request = {
                url: globals.API_URL+'/auth',
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            };
            return axios(request).then((response) => {
                if (!response) {
                    return Promise.reject()
                }
                subscribeUser();
                return Promise.resolve()
            }).catch((e) => {
                return Promise.reject()
            })
        }else{
            return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
        }
       //

    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
