import simpleRestProvider from './hycos-simple-rest';
import axios from "axios"
import {fetchUtils} from "react-admin";
import {globals} from "./globals";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Accept', 'application/json');
    return (token) ? fetchUtils.fetchJson(url, options) : Promise.reject();
};

const dataProvider = simpleRestProvider(globals.API_URL, httpClient);

//TODO make myDataProvider modular like here => https://stackoverflow.com/questions/54202340/react-admin-imageinput-to-upload-images-to-rails-api
const myDataProvider = {
    ...dataProvider,
    getList: (resource, params) => {
        if (resource === 'people') {
            return dataProvider.getList(resource, params)
        }
        return dataProvider.getList(resource, params)
    },
    update: (resource, params) => {
        if (resource === 'device') {
            let settings = params.data;
            let data = JSON.stringify({
                "action": "update",
                "target": params.data.IP_Terminal,
                "type": "setConfig",
                "data": {"pass": "123456", "config": JSON.stringify(settings)}
            });

            let config = {
                method: 'post',
                url: `https://${params.data.IP_Device_PC}/terminal/newDevice`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return axios(config)
                .then(response => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        config: {
                            ...response.data
                        }
                    }
                })).catch(function (error) {
                    console.log('Could not connect to device, ' + error)
                });
        }

        return dataProvider.update(resource, params);
    },
    create: (resource, params) => {
                 if (resource === 'person' && params.data.picture.rawFile instanceof File) {
                    // Freshly dropped pictures are File objects and must be converted to base64 strings
                    const newPictures = params.data.picture.rawFile;
                    //TODO implement user creation to terminal, fetch all devices
                    const getBase64 =convertFileToBase64(newPictures).then((r) => {
                        return r
                    }).catch((e) => console.log(e))


                    Promise.all([getBase64]).then(function (values){
                        let config = {
                            method: 'post',
                            url: `http://${params.data.IP_Device_PC}/terminal`,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: "data",
                        };
                        return axios(config).then(response => dataProvider.create(resource, {
                            ...params,
                            data: {
                                ...params.data,
                                config: {
                                    ...response.data
                                }
                            }
                        })).catch((error) => {
                            throw new Error('Registration failed, ' + error)
                     })
                    })                           // let data = JSON.stringify({"target":params.data.IP_Terminal,"type":"person/create","data":{"pass":"123456", "config": JSON.stringify(settings)}})
                }
               if (resource === 'device') {

                    //TODO getConfig => setConfig

                    let settings = params.data;
                    let data = JSON.stringify({
                        "action": "create",
                        "target": params.data.IP_Terminal,
                        "type": "setConfig",
                        "data": {"pass": "123456", "config": JSON.stringify(settings)}
                    });
                    let config = {
                        method: 'post',
                        url: `https://${params.data.IP_Device_PC}/terminal/newDevice`,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    return axios(config)
                        .then(response => dataProvider.create(resource, {
                            ...params,
                            data: {
                                ...params.data,
                                config: {
                                    ...response.data
                                }
                            }
                        }))
                        .catch(function (error) {
                            throw new Error('Could not connect to device, ' + error)
                        });
                }

        // fallback to the default implementation
        return dataProvider.create(resource, params);
    }
};

const mySecondDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        console.log(resource, params)
        return dataProvider.update(resource, params);
    }
}
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });

export default mySecondDataProvider;
