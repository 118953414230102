import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles, Tooltip,
  Typography
} from '@material-ui/core';
import {globals} from "../../../resources/data/globals";
import PushButton from 'react-push-notification-button'
import { subscribeUser, unSubscribeUser} from "../../../resources/workers/subscription";
import EmailChips from "./EmailChips";
import {Error, Loading, useQueryWithStore} from "react-admin";

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
const Notifications = ({ className, ...rest }) => {
  const classes = useStyles();
  const [isCheck, setCheck] = React.useState();

  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'dashboard',
    payload: { id: 'check_email' }
  });

  if (!loaded) return <Loading />;
  if (error) return <Error />;

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Manage the notifications"
          title="Notifications"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Alert - Notifications
              </Typography>

              <FormControlLabel
                control={(
                  <Checkbox />
                )}
                label="Email"
                onChange={e=> {
                  setCheck(e.target.checked)
                }}
              />

              {(data.send_mail) ? <EmailChips/> : null}
{/*              <FormControlLabel
                  control={(
                      <Checkbox />
                  )}
                  label="Push Notifications"
              />*/}

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
