import {globals} from "../data/globals";


const convertedVapidKey = urlBase64ToUint8Array(globals.VAPID_PUBLIC_KEY)

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function sendSubscription(subscription) {
    return fetch(`${globals.API_URL}/notification/subscribe`, {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            'Accept-Encoding': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

function sendUnsubscription(subscription) {
    return fetch(`${globals.API_URL}/notification/unsubscribe`, {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            'Accept-Encoding': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
}

export function subscribeUser() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function(registration) {
            if (!registration.pushManager) {
                console.log('Push manager unavailable.')
                return
            }

            registration.pushManager.getSubscription().then(function(existedSubscription) {
                if (existedSubscription === null) {
                    console.log('No subscription detected, make a request.')
                    registration.pushManager.subscribe({
                        applicationServerKey: convertedVapidKey,
                        userVisibleOnly: true,
                    }).then(function(newSubscription) {
                        console.log('New subscription added.')
                        sendSubscription(newSubscription)
                    }).catch(function(e) {
                        if (Notification.permission !== 'granted') {
                            console.log('Permission was not granted.')
                        } else {
                            console.error('An error ocurred during the subscription process.', e)
                        }
                    })
                } else {
                    console.log('Existed subscription detected.')
                }
            })
        })
            .catch(function(e) {
                console.error('An error ocurred during Service Worker registration.', e)
            })
    }
}

export function unSubscribeUser(){
        navigator.serviceWorker.ready.then(function(reg) {
            reg.pushManager.getSubscription().then(function(subscription) {
                subscription.unsubscribe().then(function(successful) {
                    sendUnsubscription(subscription);
                    console.log(subscription)// You've successfully unsubscribed

                }).catch(function(e) {
                    // Unsubscription failed
                })
            })
        });
}
