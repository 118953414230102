import React from 'react';
import {Error, Loading, useQueryWithStore} from "react-admin";

const APIContext = React.createContext(null);
export const DashboardContextProvider = ({children}) => {

    const { loaded, error, data } = useQueryWithStore({
        type: 'getOne',
        resource: 'dashboard',
        payload: { id: 'widgets' }
    });



    if (!loaded) return <Loading />;
    if (error) return <Error />;

    return(
        <APIContext.Provider value={data}>
            {children}
        </APIContext.Provider>
    )
}

export function useAPI() {
    const context = React.useContext(APIContext);
    if (context === undefined) {
        return Promise.reject();
    }
    return context;
}
