"use strict";
let __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (let s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {value: true});
let query_string_1 = require("query-string");
let ra_core_1 = require("ra-core");
/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
exports.default = (function (apiUrl, httpClient) {
    if (httpClient === void 0) {
        httpClient = ra_core_1.fetchUtils.fetchJson;
    }
    return ({
        getList: function (resource, params) {
            let _a = params.pagination, page = _a.page, perPage = _a.perPage;
            let _b = params.sort, field = _b.field, order = _b.order;
            let rangeStart = (page - 1) * perPage;
            let rangeEnd = page * perPage - 1;
            let query = {
                //modification to match laravel backend
                page: JSON.stringify(page),
                perPage: JSON.stringify(perPage),
                filter: JSON.stringify(params.filter),
                sort: JSON.stringify(_b)
            };
            let url = apiUrl + "/" + resource + "?" + query_string_1.stringify(query);
            return httpClient(url, {
                // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                headers: new Headers({
                    Range: resource + "=" + rangeStart + "-" + rangeEnd,
                }),
            }).then(function (_a) {
                let headers = _a.headers, json = _a.json;
                if (!headers.has('content-range')) {
                    throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: json,
                    total: parseInt(headers.get('content-range').split('/').pop(), 10),
                };
            });
        },
        getOne: function (resource, params) {
            return httpClient(apiUrl + "/" + resource + "/" + params.id).then(function (_a) {
                let json = _a.json;
                return ({
                    data: json,
                });
            });
        },
        getMany: function (resource, params) {
            let query = {
                filter: JSON.stringify({id: params.ids}),
            };
            let url = apiUrl + "/" + resource + "?" + query_string_1.stringify(query);
            return httpClient(url).then(function (_a) {
                let json = _a.json;
                return ({data: json});
            });
        },
        getManyReference: function (resource, params) {
            let _a;
            let _b = params.pagination, page = _b.page, perPage = _b.perPage;
            let _c = params.sort, field = _c.field, order = _c.order;
            let query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(__assign(__assign({}, params.filter), (_a = {}, _a[params.target] = params.id, _a))),
            };
            let url = apiUrl + "/" + resource + "?" + query_string_1.stringify(query);
            return httpClient(url).then(function (_a) {
                let headers = _a.headers, json = _a.json;
                if (!headers.has('content-range')) {
                    throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: json,
                    total: parseInt(headers.get('content-range').split('/').pop(), 10),
                };
            });
        },
        update: function (resource, params) {
            return httpClient(apiUrl + "/" + resource + "/" + params.id, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                let json = _a.json;
                return ({data: json});
            });
        },
        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(apiUrl + "/" + resource + "/" + id, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                });
            })).then(function (responses) {
                return ({
                    data: responses.map(function (_a) {
                        let json = _a.json;
                        return json.id;
                    })
                });
            });
        },
        create: function (resource, params) {
            return httpClient(apiUrl + "/" + resource, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                let json = _a.json;
                return ({
                    data: __assign(__assign({}, params.data), {id: json.id}),
                });
            });
        },
        delete: function (resource, params) {
            return httpClient(apiUrl + "/" + resource + "/" + params.id, {
                method: 'DELETE',
            }).then(function (_a) {
                let json = _a.json;
                return ({data: json});
            });
        },
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(apiUrl + "/" + resource + "/" + id, {
                    method: 'DELETE',
                });
            })).then(function (responses) {
                return ({
                    data: responses.map(function (_a) {
                        let json = _a.json;
                        return json.id;
                    })
                });
            });
        },
    });
});
