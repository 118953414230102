import React from 'react';
import {Error} from "react-admin"
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {useAPI} from "./DashboardContext";
import { translate } from 'react-admin';


const useStyles = makeStyles(({
    root: {
        height: '100%'
    },
    image: {
        height: 48,
        width: 48
    }
}));

const LatestVisitors = ({translate, className, ...rest}) => {
    const classes = useStyles();
    const {last5Visitors} = useAPI()

    if(last5Visitors){
        return (
            <Card
                className={clsx(classes.root, className)}
                {...rest}
            >
                <CardHeader title={translate("dashboard.latestVisitors.title")}
                />
                <Divider/>
                <List>
                    {last5Visitors.map((person, i) => (
                        <ListItem
                            divider={i < person.length - 1}
                            key={person.id}
                            button
                            component={"a"}
                            href={'/#/people/' + person.id + "/show"}
                        >
                            <ListItemAvatar>
                                <img
                                    alt="image"
                                    className={classes.image}
                                    src={"data:image/jpeg;base64," + person.picture}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={person.name}
                                secondary={
                                    <div>
                                        <div>{person.temp} °C - {(person.mask == 0 || person.mask == -1) ? " Mask: ❌ " : "Mask: ✔️"}</div>
                                        <div>Updated {person.checkTime}</div>
                                    </div>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
                <Divider/>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                >
                    <Button
                        color="primary"
                        endIcon={<ArrowRightIcon/>}
                        size="small"
                        variant="outlined"
                        href={"/#/people"}
                    >
                        {translate("buttons.view_all")}
                    </Button>
                </Box>
            </Card>
        );
    }else {
        return <Error/>
    }
};

LatestVisitors.propTypes = {
    className: PropTypes.string
};

export default translate(LatestVisitors);
