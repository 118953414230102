import {createMuiTheme} from '@material-ui/core';
import typography from './typography';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#116CB4',
        },
        secondary: {
            light: '#9cc351',
            main: '#84B526',
            dark: '#5c7e1a',
            contrastText: '#fff',
        },
        background: {
            default: '#F4F6F8',
        },
    },
    shape: {
        borderRadius: 5,
    },
    overrides: {
        RaLayout:{
          content:{
              marginLeft: "2%",
              marginRight: "2%"
          }
        },
        RaMenuItemLink: {
            active: {
                borderRight: '4px solid #116CB4',
                backgroundColor: 'rgba(17, 108, 180, 0.2)',
            },
            hover:{
                backgroundColor: "red"
            }
        },
        RaListToolbar:{
          toolbar:{
              backgroundColor: ""
          }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        RaSidebar:{
            root:{
                borderRight: '1px solid #e0e0e3',
                //boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
            },
          drawerPaper:{
              backgroundColor: ""
          }
        },
        MuiButton: {
             contained: {
                 backgroundColor: '#fff',
                 color: '#4f3cc9',
                 boxShadow: 'none',
             },
         },
       MuiAppBar: {
           root:{
             border: 'none'
           },
             colorSecondary: {
                 color: '#808080',
                 backgroundColor: '#fff',
             },

         },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
    typography
});

export default theme;
